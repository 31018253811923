// @ts-nocheck
import React from 'react';
import { Platform } from 'react-native';
import { Stack, Heading, useTheme } from 'tamagui';
import { Flex } from 'components/base/General';

// TODO replace with settings color
const ChildPage = (props: {
  boxPadding?: number;
  subtitle?: string;
  children: any;
  bg: any;
}) => {
  const theme = useTheme();
  return (
    <Flex
      flexGrow={1}
      minHeight={Platform.OS === 'web' ? 600 : 0}
      bg={props.bg ? props.bg : theme.warmBG}
      alignItems="stretch"
      my={props.my ? props.my : 0}
      py={props.py ? props.py : 20}
    >
      <Stack
        my={props.boxPadding ? props.boxPadding : 1}
        $sm={{
          my: props.boxPadding ? props.boxPadding : 1,
        }}
        $md={{
          my: props.boxPadding ? props.boxPadding : 15,
        }}
      >
        {props.subtitle ? (
          <Heading
            fontWeight="bold"
            fontSize="$lg"
            textAlign="center"
            color={theme.mainColor.get()}
          >
            {props.subtitle}
          </Heading>
        ) : null}
      </Stack>
      {props.children}
    </Flex>
  );
};

export default ChildPage;
