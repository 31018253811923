import React from 'react';
import Head from 'next/head';
import ChildPage from 'components/main/ChildPage';
import { ScrollView, Stack } from 'tamagui';
import BigNormalPanda from 'components/utility/BigNormalPanda';
import Panda from 'components/utility/TransparentPanda';
import { useColorModeValue } from 'utils/hooks';
import { useTheme, H1 } from 'tamagui';

export default function Custom404() {
  const theme = useTheme();
  const ColoredPanda = useColorModeValue(<Panda />, <BigNormalPanda />);
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Oops! The page you're looking for can't be found. Please check the URL or return to the homepage."
        />
        <title>404 - Page Not Found | Pandaist</title>
        <link rel="canonical" href="https://pandaist.com/404" />
        <meta
          name="twitter:description"
          content="Oops! The page you're looking for can't be found. Please check the URL or return to the homepage."
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="Pandaist | Learn Chinese by Reading"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="404 - Page Not Found | Pandaist" />
        <meta
          property="og:description"
          content="Oops! The page you're looking for can't be found. Please check the URL or return to the homepage."
        />
        <meta property="og:url" content="https://pandaist.com/404" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="404 - Page Not Found | Pandaist" />
        <meta
          property="og:image"
          content={'https://cdn.pandaist.com/main/images/bigpanda.png'}
        />
        <meta
          name="twitter:image"
          content={'https://cdn.pandaist.com/main/images/bigpanda.png'}
        />
      </Head>
      <ChildPage subtitle={'404 Error'}>
        <ScrollView
          width={700}
          alignSelf="center"
          py={35}
          flex={1}
          height="100%"
          maxWidth="100%"
        >
          <H1 fontSize="$3xl" textAlign="center" color={theme.mainColor}>
            We seem to have accidentally misplaced this page (or you're lost)
          </H1>
          {ColoredPanda}
        </ScrollView>
      </ChildPage>
    </>
  );
}
