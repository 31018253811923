import React from 'react';
import { Image } from 'react-native';
import MrFattyPants from 'resources/img/mrfattypantsbignormal.png';
import { Stack } from 'tamagui';

// TODO check if alt text can be set

const BigNormalPanda = () => {
  return (
    <Stack alignItems="center" mx={5}>
      <Image
        source={MrFattyPants}
        style={{
          marginTop: 50,
          width: 250,
          height: 275,
        }}
      />
    </Stack>
  );
};

export default BigNormalPanda;
