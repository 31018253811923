import React from 'react';
import { Image } from 'react-native';
import { Stack } from 'tamagui';
import MrFattyPants from 'resources/img/mrfattypantstrans.png';

// TODO check if alt text can be set

const TransparentPanda = () => {
  return (
    <Stack
      alignItems="center"
      style={{
        opacity: 0.2,
      }}
    >
      <Image
        source={MrFattyPants}
        style={{
          marginTop: 50,
          width: 250,
          height: 275,
        }}
      />
    </Stack>
  );
};

export default TransparentPanda;
